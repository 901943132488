<template>
	<div class="header_box">
		<div class="header_top" v-if="configInfo">
			<div class="logo">
				<img :src="configInfo.con_logo" alt="" @click="goHome" />
			</div>
			<div class="web_info" :style="{width: infowidth}">
				<!--切换城市-->
				<div class="choose_city" v-if="isMian">
					<i class="el-icon-location-outline"></i>
					<span class="cityName">贵州省</span>
					<span class="switchBtn" @click="switchCitiesFun">[切换城市]</span>
				</div>
				<!--登录信息-->
				<div class="login_info" v-if="!isMian">
					<!--未登录-->
					<div class="nologin" v-if="!isLogin" @click="showLogin">
						<img src="@/views/images/nan.png" alt="" />
						<span>登录</span>
					</div>
					<div class="nologin" v-else>
						<el-dropdown @command="logout">
							<div class="logined_info el-dropdown-link">
								<img :src="userinfo.stu_headimg" alt="" v-if="userinfo.stu_headimg" />
								<span v-else>
									<img v-if="userinfo.stu_sex == '女'" src="@/views/images/nv.png" alt=""  />
									<img v-else src="@/views/images/nan.png" alt="" />
								</span>
								<span>{{userinfo.stu_name}}</span>
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="a">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<!--监督电话 服务电话-->
				<div class="phone_info" v-if="!isLogin">
					<div class="phone_box phone_boxone">
						<img src="@/views/images/phone_01.png" alt="" />
						<div class="phone_box_right" v-if="configInfo.con_zbphone">
							<p class="phone_text">值班电话</p>
							<p>{{configInfo.con_zbphone}}</p>
						</div>
						<div class="phone_box_right" v-else>
							<p class="phone_text">客服电话</p>
							<p>{{configInfo.con_telphone}}</p>
						</div>
					</div>
					<div class="phone_box">
						<img src="@/views/images/phone_02.png" alt="" />
						<div class="phone_box_right">
							<p class="phone_text">监督电话 </p>
							<p>{{configInfo.con_jdphone}}</p>
						</div>
					</div>
				</div>
				<!-- 登录后 -->
				<div class="after_login" v-else>
					<div class="learning_box" @click="toLCenter">
						<img src="@/views/images/learning_icon.png" alt="" />
						<span>学习中心</span>
					</div>
					<div class="learning_box" @click="toCourseList">
						<img src="@/views/images/sign_icon.png" alt="" />
						<span>立即报名</span>
					</div>
				</div>
			</div>
		</div>
		<!--导航栏-->
		<div class="web_nav">
			<ul>
				<li :class="{active:isHome}" @click="goHome">
					<div class="liBox">首页</div>
				</li>
				<li :class="{active:menuChose == 'course'}" @click="toCourseList">
					<div class="liBox">选课中心</div>
				</li>
				<li :class="{active:menuChose == 'special'}"  @click="tospecialList">
					<div class="liBox">专题培训</div>
				</li>
				<li v-for="(item,index) in menuList" :key="index" :class="{active:menuChose == item.col_id}" @click="menuClick(item)">
					<div class="liBox">{{item.col_name}}</div>
				</li>
			</ul>
		</div>
		<!-- 切换城市弹窗 -->
		<el-dialog title="切换城市" :visible.sync="switchCitiesVisible" width="500px" custom-class="switchCitiesDialog">
			<div class="switchCities_box">
				<p class="fb fs_16 c_333">亲爱的学员您好：</p>
				<p class="switchTip">切换城市分站，让我们为您提供更准确的培训服务</p>
				<p class="fb fs_18 c_333 mt_22">当前定位</p>
				<div class="currPosition" @click="cuurCity">
					<i class="el-icon-location-outline"></i>
					<span>{{locationName}}</span>
				</div>
				<p class="fb fs_18 c_333 mt_22 mb_16">切换城市</p>
				<div class="cityList">
					<p v-for="(item,index) in cityList" :key="index" @click="chooseCity(item)">{{item.are_name}}</p>
				</div>
			</div>
		</el-dialog>
		<!-- 登录弹窗 -->
		<el-dialog :visible.sync="loginVisible" width="406px" custom-class="loginDialog" :close-on-click-modal="false">
			<div class="H_UserLogin">
				<div class="H_LoginTxet">
					<img src="@/views/images/nan.png" />
					<p>学员登录</p>
				</div>
				<div class="H_inputDiv">
					<img src="@/views/images/h_account_icon.png" />
					<input type="text" v-model="H_account" placeholder="请输入身份证号/手机号" maxlength="18" @blur="blurH_account" />
					<span class="error" v-if="isAccount">请输入身份证号/手机号</span>
				</div>
				<div class="H_inputDiv">
					<img src="@/views/images/h_pwd_icon.png" />
					<input type="password" v-model="H_pwd" placeholder="请输入密码" minlength="6" maxlength="18" show-password @blur="blurPassword"/>
					<span class="error" v-if="isPwd">请输入密码</span>
				</div>
				<div class="H_verifyDiv">
				  <drag-verify
				    ref="H_dragVerify"
					:width="326"
				    :isPassing.sync="H_isPassing"
				    text="按住滑块，拖动到最右边"
				    successText="验证通过"
				    handlerIcon="el-icon-d-arrow-right"
				    successIcon="el-icon-circle-check"
				  >
				  </drag-verify>
				  <span class="error" v-if="isDrag">请拖动滑块</span>
				</div>
				<div class="H_isRemember">
					<el-checkbox-group v-model="H_isRemember">
					    <el-checkbox label="记住密码"></el-checkbox>
					</el-checkbox-group>
				</div>
				<div class="H_loginBtn" @click="LoginFun">登录</div>
				<div class="H_registerAndForget">
					<span class="H_register" @click="toRegister">注册</span>
					<span class="H_Forget" @click="tofindPassword">忘记密码</span>
				</div>
			</div>
		</el-dialog>
		<!-- 右侧浮窗 -->
		<offside :data="isLogin" @goto-helpcenter="gotoHelpcenter()"></offside>
		
		<el-dialog
		  :visible.sync="NoticeVisible"
		  width="40%"
		  center
		  :close-on-click-modal="false"
		  :close-on-press-escape="false"
		  @close="closeNotice"
		  class="Noticedialog">
		  <div class="tips">
		  	<p>尊敬的各位学员：</p>
			<p>2023年遵义市专业技术人员继续教育专业课培训已开始，您可根据从事行业自行选择课程进行学习。</p>
		  	<p style="color: red;">1.公需课学习网址:贵州省专业技术继续教育平台:http://www.gzjxjy.gzsrs.cn/index.html</p>
		  	<p style="color: red;">2.教育类课程已上线，请广大学员进行购买。</p>
			<p style="color: red;">3.需进行集体报名请咨询刘老师，电话：18486382237</p>
			<p style="color: red;">4.平台及开具发票问题请咨询客服，电话：4008815029</p>
			<div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 20px;">
				<el-button type="primary" @click="closeNotice">关闭</el-button>
				<div style="margin-top: 15px;">{{times}}秒后关闭</div>
			</div>
		  </div>
		</el-dialog>
		
		<el-dialog
		  :visible.sync="beOnlineVisible"
		  width="40%"
		  center
		  :close-on-click-modal="false"
		  :close-on-press-escape="false"
		  @close="closebeOnline"
		  class="Noticedialog">
		  <div class="tips">
		  	<div v-for="(item,index) in beOnlineList" :key="index" class="beOnline_item">
				<div class="beOnline_top">
					<div class="beOnline_title">{{item.inf_title}}</div>
					<el-button type="primary" @click="gotoDetails(item)" size="small">查看详情</el-button>
				</div>
				<div class="beOnline_summary">{{item.inf_summary}}</div>
			</div>
			<div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 20px;">
				<el-button type="primary" @click="closebeOnline">关闭</el-button>
				<div style="margin-top: 15px;">{{Onlinetimes}}秒后关闭</div>
			</div>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import location from '/src/components/positionLocation.js'
	import Bus from '/src/views/js/bus.js';  
	import dragVerify from 'vue-drag-verify2'
	import offside from "/src/components/offside";//右侧浮窗
	export default {
		components:{
		    dragVerify,
			offside
		},
		watch:{
			H_isPassing(val){
				if(val){
					this.isDrag = false;
				}
			}
		},
		data() {
			return {
				isMian: true, //是否为省平台
				isLogin: false, //是否登录
				logo: require("./images/logo.png"), //logo图片
				switchCitiesVisible: false, //切换城市弹窗
				locationName: "",//当前地理位置
				cityName:"",//选择的市
				loginVisible:false,//登录弹窗
				H_isPassing:false,//滑块验证
				H_account:"",//账号
				H_pwd:"",//密码
				H_isRemember:[],//是否记住密码
				cityList:[],//城市列表
				configInfo:null,//网站配置信息
				isAccount:false,//账号验证
				isPwd:false,//密码验证
				isDrag:false,//滑块验证
				menuList:[],//栏目列表
				isHome:true,//首页选中
				menuChose:"",//选中菜单
				userinfo:{},//登录人信息
				infowidth:"682px",
				are_code:"",//城市code
				NoticeVisible:false,
				times:10,//关闭倒计时
				beOnlineList:[],//公告列表
				beOnlineVisible:false,//公告弹窗
				Onlinetimes:10,//关闭倒计时
				countdownId:null,
				OnlinecountdownId:null,
			}
		},
		methods: {
			//60秒倒计时
			countdown(vm) {
				if (vm.times == 0) {
					vm.times = 10;
					//关闭提示
					vm.closeNotice()
					return false;
				} else {
					vm.times--;
				}
				this.countdownId = setTimeout(function() {
					console.log("countdown：" + vm.times)
					vm.countdown(vm);
				}, 1000);
			},
			//60秒倒计时
			Onlinecountdown(vm) {
				if (vm.Onlinetimes == 0) {
					vm.Onlinetimes = 10;
					//关闭提示
					vm.closebeOnline()
					return false;
				} else {
					vm.Onlinetimes--;
				}
				this.OnlinecountdownId = setTimeout(function() {
					console.log("Onlinecountdown：" + vm.Onlinetimes)
					vm.Onlinecountdown(vm);
				}, 1000);
			},
			//关闭提示
			closeNotice(){
				clearTimeout(this.countdownId);
				this.NoticeVisible = false;
				this.$cookies.set(this.PublicJs.isNotice,true,0);
			},
			//关闭提示
			closebeOnline(){
				if(this.beOnlineVisible){
					clearTimeout(this.OnlinecountdownId);
					this.beOnlineVisible = false;
					this.$cookies.set(this.PublicJs.isbeOnline,true,0);
					// if(this.$cookies.get(this.PublicJs.isNotice)){
					// 	this.NoticeVisible = false
					// }else{
					// 	this.countdown(this);
					// 	this.NoticeVisible = false;
					// }
				}
			},
			//切换城市弹窗
			switchCitiesFun() {
				this.switchCitiesVisible = true;
				var that = this;
				setTimeout(function(){
					that.getLocation(); // 调用获取地理位置
				},100);
			},
			/** 获取高德地图定位 */
			getLocation() {
				let _that = this
				let geolocation = location.initMap('map-container') // 定位
				AMap.event.addListener(geolocation, 'complete', result => {
					_that.locationName = result.addressComponent.district
				})
			},
			//选择城市
			chooseCity(item){
				window.location.href = item.domain + "/web";
				
			},
			// 登录弹窗
			showLogin(){
				this.loginVisible = true
			},
			//跳转注册页
			toRegister(){
				this.loginVisible = false;
				this.$router.push({
					path: "/register"
				});
			},
			//忘记密码
			tofindPassword(){
				this.loginVisible = false;
				this.$router.push({
					path: "/findPassword"
				});
			},
			//获取所有市
			getCity(){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/city.html", {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.cityList = response.data.data
						window.sessionStorage.setItem('cityList',this.PublicJs.Encrypt(JSON.stringify(this.cityList)));//城市列表
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			// 点击当前城市
			cuurCity(){
				var cityList = this.cityList;
				var cuurCity = {}
				for(var i=0;i<cityList.length;i++){
					if(this.locationName == cityList[i].are_name){
						cuurCity = cityList[i]
					}
				}
				if(JSON.stringify(cuurCity) == "{}"){
					this.$message({
						message: "您选择的城市暂无学习平台，请重新选择",
						type: 'warning',
						showClose: true
					});
				}else{
					window.location.href = cuurCity.domain + "/web";
				}
			},
			//学员登录
			LoginFun(){
				//账号失去焦点
				this.blurH_account();
				//密码失去焦点
				this.blurPassword();
				//滑块验证
				if(!this.H_isPassing){
					this.isDrag = true;
				}
				if(this.H_account && this.H_pwd && this.H_isPassing){
					this.$http.post(this.PublicJs.publicPath + "/api/student.index/login.html", {
						account:this.PublicJs.Encrypt(this.H_account.toUpperCase()),
						password:this.PublicJs.Encrypt(this.H_pwd),
						are_code:this.are_code
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							if(this.H_isRemember){
								this.$cookies.set("account",this.PublicJs.Encrypt(this.H_account),"14d");
								this.$cookies.set("password",this.PublicJs.Encrypt(this.H_pwd),"14d");
							}else{
								this.$cookies.remove("account");
								this.$cookies.remove("password");
							}
							window.sessionStorage.setItem('user_id',this.PublicJs.Encrypt(response.data.data.userinfo.user_id))
							window.sessionStorage.setItem('token',response.data.data.userinfo.token)
							window.sessionStorage.setItem('userinfo',this.PublicJs.Encrypt(JSON.stringify(response.data.data.userinfo)))
							this.userinfo = response.data.data.userinfo;
							this.$router.push({
								path: "/LearningCenter"
							});
						}else if(response.data.code == -1){
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
							setTimeout(function(){
								window.location.href = response.data.data.data.domain + "/web";
							},2000);
						}else{
							this.H_pwd = "";
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						console.log(response)
					});
				}
			},
			//账号失去焦点
			blurH_account(){
				if(!this.H_account){
					this.isAccount = true;
				}else{
					this.isAccount = false;
				}
			},
			//密码失去焦点
			blurPassword(){
				if(!this.H_pwd){
					this.isPwd = true;
				}else{
					this.isPwd = false;
				}
			},
			//获取栏目数据
			getMenu(){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/column.html", {
					page:1,
					limit:999
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.menuList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//点击菜单
			menuClick(item){
				this.isHome = false
				this.menuChose = item.col_id;
				if(item.col_name == "精品课程"){
					this.$router.push({
						path: "/WcourseList"
					});
				}else{
					this.$router.push({
						path: "/newsList",
						query: {
							col_id: this.PublicJs.Encrypt(item.col_id),
							col_name:this.PublicJs.Encrypt(item.col_name),
						}
					});
				}
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt(item.col_id))
			},
			//跳转首页
			goHome(){
				this.isHome = true;
				this.menuChose = "";
				this.$router.push({
					path: "/"
				});
				window.sessionStorage.setItem('isHome',true)
				window.sessionStorage.setItem('menuChose',"")
			},
			//跳转帮助中心
			gotoHelpcenter(){
				this.isHome = false
				this.menuChose = 4;
				this.$router.push({
					path: "/newsList",
					query: {
						col_id: this.PublicJs.Encrypt(4),
						col_name:this.PublicJs.Encrypt("帮助中心"),
					}
				});
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt(4))
			},
			//跳转学习中心
			toLCenter(){
				this.$router.push({
					path: "/LearningCenter"
				});
			},
			//跳转课程列表
			toCourseList(){
				this.isHome = false
				this.menuChose = "course";
				Bus.$emit('busIsHome', false);//是否为省平台
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("course"))
				this.$router.push({
					path: "/courseList"
				});
			},
			//跳转专题培训
			tospecialList(){
				this.isHome = false
				this.menuChose = "special";
				Bus.$emit('busIsHome', false);//是否为省平台
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("special"))
				this.$router.push({
					path: "/specialList"
				});
			},
			//退出登录
			logout(command){
				if(command == "a"){
					this.$http.post(this.PublicJs.publicPath + "/api/student.Info/logout.html", {
						token:window.sessionStorage.getItem('token')
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							this.$router.go(0)
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				}
			},
			// 查询首页弹出公告
			getHomeInfo(){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/getHomeInfo.html", {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.beOnlineList = response.data.data
						if(this.beOnlineList.length){
							this.Onlinecountdown(this);
							this.beOnlineVisible = true;
						}else{
							// if(this.$cookies.get(this.PublicJs.isNotice)){
							// 	this.NoticeVisible = false
							// }else{
							// 	this.countdown(this);
							// 	this.NoticeVisible = true;
							// }
						}
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//查看详情
			gotoDetails(item){
				this.$router.push({
					path: "/newsDetails",
					query: {
						col_name:this.PublicJs.Encrypt("通知公告"),
						ids:this.PublicJs.Encrypt(item.inf_id)
					}
				});
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt(1))
				this.closebeOnline();
			},
		},
		created() {
			if(this.$cookies.get(this.PublicJs.isbeOnline)){
				this.beOnlineVisible = false;
				// if(this.$cookies.get(this.PublicJs.isNotice)){
				// 	this.NoticeVisible = false
				// }else{
				// 	this.countdown(this);
				// 	this.NoticeVisible = true;
				// }
			}else{
				// 查询首页弹出公告
				this.getHomeInfo();
			}
			
			this.isMian = false;
			this.are_code = this.$cookies.get("are_code")
			this.cityName = this.$cookies.get("cityName")
			this.infowidth = "auto"
			
			//获取所有市
			this.getCity();
			//配置信息
			this.configInfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('configInfo')))
			//获取栏目数据
			this.getMenu();
			if(this.$cookies.get("account")){
				this.H_account = this.PublicJs.Decrypt(this.$cookies.get("account"))
				this.H_isRemember = true;
			}
			if(this.$cookies.get("password")){
				this.H_pwd = this.PublicJs.Decrypt(this.$cookies.get("password"))
			}
			if(window.sessionStorage.getItem('isHome')){
				if(window.sessionStorage.getItem('isHome') == "true"){
					this.isHome = true
				}else{
					this.isHome = false
				}
				this.menuChose = this.PublicJs.Decrypt(window.sessionStorage.getItem('menuChose'));
			}
			if(window.sessionStorage.getItem('userinfo')){
				this.isLogin = true;
				this.userinfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('userinfo')))
			}
		},
		mounted () {
			Bus.$on('busIsHome', (val) => { //Hub接收事件(是否为省平台)
				this.isHome = val;
			});
			Bus.$on('busmenuChose', (val) => { //Hub接收事件(是否为省平台)
				this.menuChose = val;
			});
			
		}
	}
</script>

<style scoped="scoped">
	.tips p{
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: bold;
		text-indent: 2em;
		word-break: break-all;
	}
	.header_top {
		width: 1200px;
		margin: 0 auto;
		height: 125px;
		background: #fff;
	}

	.header_top:after,
	.web_info::after {
		display: block;
		content: "";
		clear: both;
	}

	.logo {
		width: 480px;
		height: 125px;
		line-height: 125px;
		float: left;
	}

	.logo img {
		display: inline-block;
		vertical-align: middle;
		width: auto;
		height: 80px;
		cursor: pointer;
	}
	.logoText{
		width: 588px;
		height: 125px;
		float: left;
		line-height: 111px;
		background-image: url(images/logoText.png);
		background-position: left 44px;
		background-repeat: no-repeat;
		margin-left: -60px;
		padding-left: 70px;
	}
	.logoText p{
		font-size: 30px;
		color: #333;
		font-family: "HuXiaoBoNanShen";
	}

	.web_info {
		float: right;
		/* width: 682px; */
		position: relative;
		height: 60px;
		margin-top: 32.5px;
	}

	.choose_city {
		float: left;
		height: 60px;
		line-height: 60px;
		font-size: 0;
	}

	.choose_city i {
		font-size: 24px;
		color: #4d6ef2;
		margin-right: 10px;
		vertical-align: middle;
	}

	.choose_city span {
		font-size: 16px;
		color: #333333;
		font-family: "微软雅黑";
		vertical-align: middle;
		font-weight: lighter;
	}

	.choose_city .cityName {
		font-size: 20px;
		color: #4d6ef2;
		margin-right: 10px;
	}

	.choose_city .switchBtn {
		cursor: pointer;
	}

	.phone_info,
	.after_login {
		float: right;
		font-size: 0px;
	}

	.phone_box {
		width: 180px;
		height: 60px;
		display: inline-block;
		vertical-align: top;
		border: 1px solid #eeeeee;
		position: relative;
		padding-left: 66px;
		line-height: 60px;
	}

	.phone_box img {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 16px;
		top: 13px;
	}

	.phone_box.phone_boxone {
		margin-right: 20px;
	}

	.phone_box_right {
		width: 100%;
		height: 40px;
		margin-top: 7px;
		text-indent: 12px;
		position: relative;
	}

	.phone_box_right::before {
		width: 1px;
		height: 40px;
		background-color: #EEEEEE;
		content: "";
		position: absolute;
		left: 0;
		top: 3px;
	}

	.phone_box_right p {
		color: #282b43;
		font-size: 12px;
		font-family: "微软雅黑";
		line-height: 22px;
	}

	.learning_box {
		display: inline-block;
		line-height: 60px;
		margin-left: 45px;
		cursor: pointer;
	}

	.learning_box img {
		width: 28px;
		height: 26px;
		vertical-align: middle;
		margin-right: 12px;
	}

	.learning_box span {
		color: #333333;
		font-size: 14px;
		vertical-align: middle;
	}

	.login_info {
		display: inline-block;
		vertical-align: middle;
		margin-left: 39px;
		margin-top: 12px;
		float: right;
	}

	.nologin {
		cursor: pointer;
	}

	.login_info img {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		width: 36px;
		height: 36px;
		border-radius: 18px;
	}

	.login_info span {
		color: #333333;
		font-size: 16px;
		font-family: "微软雅黑";
		display: inline-block;
		vertical-align: middle;
		font-weight: 400;
	}

	/*导航*/
	.web_nav {
		width: 100%;
		height: 60px;
		background: #4d6ef2;
		min-width: 1200px;
	}

	.web_nav ul {
		width: 1200px;
		margin: 0 auto;
		height: 100%;
		font-size: 0px;
	}

	.web_nav ul li {
		width: 160px;
		display: inline-block;

	}

	.web_nav ul li .liBox {
		width: 110px;
		margin: 0 auto;
		text-align: center;
		line-height: 60px;
		color: #ffffff;
		font-size: 16px;
		font-weight: 400;
		font-family: "微软雅黑";
		cursor: pointer;
	}

	.web_nav ul li.active .liBox {
		background: #74a2ff;
	}

	/* 切换城市弹窗 */
	.switchCities_box {
		padding: 27px 18px 0;
	}

	.switchTip {
		font-size: 14px;
		font-weight: lighter;
		border-bottom: 1px solid #E2E2E2;
		padding: 19px 0;
	}

	.currPosition {
		border: 1px solid #dbdbdb;
		border-radius: 6px;
		height: 40px;
		line-height: 40px;
		display: inline-block;
		padding: 0 20px;
		margin-top: 16px;
		color: #333;
		cursor: pointer;
		font-size: 0;
	}

	.currPosition span {
		font-size: 14px;
		vertical-align: middle;
	}

	.currPosition .el-icon-location-outline {
		color: #333;
		font-size: 20px;
		vertical-align: middle;
		margin-right: 5px;
	}

	.cityList p {
		border: 1px solid #dbdbdb;
		border-radius: 6px;
		height: 40px;
		line-height: 40px;
		display: inline-block;
		padding: 0 20px;
		margin-bottom: 20px;
		margin-right: 20px;
		cursor: pointer;
		color: #999999;
	}

	.cityList p:hover,
	.currPosition:hover {
		border: 1px solid #4D6EF2;
		color: #4D6EF2;
	}

	.currPosition:hover .el-icon-location-outline {
		color: #4D6EF2;
	}
	/* 登录弹窗 */
	.H_UserLogin{
		padding: 0 20px;
	}
	.H_LoginTxet{
		text-align: center;
	}
	.H_LoginTxet img{
		width: 76px;
		height: 76px;
	}
	.H_LoginTxet p{
		font-size: 16px;
		color: #999;
	}
	.H_inputDiv{
		width: 100%;
		height: 56px;
		border-bottom: 1px solid #E4E4E4;
		margin-top: 16px;
		line-height: 56px;
		font-size: 0;
		position: relative;
	}
	.H_inputDiv img{
		vertical-align: middle;
		margin-right: 13px;
	}
	.H_inputDiv input{
		border: none;
		background-color: transparent;
		outline: none;
		vertical-align: middle;
		width: 273px;
		height: 28px;
		color: #999;
		font-size: 14px;
	}
	.H_inputDiv input::-webkit-input-placeholder {
		color: #999; 
	} 
	.H_inputDiv input:-moz-placeholder { 
		color: #999; 
	} 
	.H_inputDiv input::-moz-placeholder { 
		color: #999; 
	} 
	.H_inputDiv input:-ms-input-placeholder { 
		color: #999; 
	}
	.H_verifyDiv{
		margin-top: 30px;
		position: relative;
	}
	.H_isRemember{
		margin: 18px 0;
		padding-left: 4px;
	}
	.isRemember .el-checkbox__input.is-focus .el-checkbox__inner{
		border-color: #DCDFE6;
	}
	.H_loginBtn{
		width: 100%;
		height: 40px;
		background: linear-gradient(0deg,#FF4936 0%, rgba(255,73,54,0.90) 100%);
		border-radius: 20px;
		box-shadow: 0px 2px 7px 0px rgba(77,110,241,0.30); 
		text-align: center;
		line-height: 40px;
		font-size: 18px;
		color: #FFF;
		cursor: pointer;
	}
	.H_registerAndForget{
		margin-top: 16px;
	}
	.H_registerAndForget span{
		font-size: 12px;
	}
	.H_register{
		color: #FF4936;
		cursor: pointer;
	}
	.H_register:hover{
		color: #ea2c18;
	}
	.H_Forget{
		color: #999;
		float: right;
		cursor: pointer;
	}
	.error{
		position: absolute;
		font-size: 12px;
		bottom: -15px;
		left: 39px;
		line-height: 12px;
		color: #f31313;
	}
	.beOnline_item{
		margin-bottom: 20px;
		border-bottom: 1px dashed #999999;
	}
	.beOnline_top{
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	.beOnline_title{
		flex: 1;
		font-weight: bold;
		font-size: 16px;
		line-height: 28px;
	}
	.beOnline_summary{
		line-height: 24px;
		padding-bottom: 15px;
		text-indent: 2em;
	}
</style>
<style>
	/* 切换城市弹窗 */
	.switchCitiesDialog {
		border-radius: 6px;
	}

	.switchCitiesDialog .el-dialog__header {
		background-color: #4D6EF2;
		padding: 0;
		height: 60px;
		line-height: 60px;
		border-radius: 6px 6px 0 0;
	}

	.switchCitiesDialog .el-dialog__title {
		color: #FFF;
		font-size: 20px;
		font-weight: lighter;
		padding-left: 20px;
	}

	.switchCitiesDialog .el-dialog__headerbtn .el-dialog__close {
		font-size: 18px;
		color: #FFF;
	}

	.switchCitiesDialog .el-dialog__body {
		padding: 0;
	}
	/* 登录弹窗 */
	.loginDialog .el-dialog__header{
		padding: 0;
	}
	.H_verifyDiv .drag_verify .dv_handler{
		border: 1px solid #EAEBEC;
		border-radius: 4px;
	}
	.H_isRemember .el-checkbox__input.is-focus .el-checkbox__inner{
		border-color: #DCDFE6;
	}
	.H_isRemember .el-checkbox {
		font-size: 12px;
	}
	.H_isRemember .el-checkbox__input.is-checked+.el-checkbox__label{
		color: #FF4936;
	}
	.H_isRemember .el-checkbox__input.is-checked .el-checkbox__inner, 
	.H_isRemember .el-checkbox__input.is-indeterminate .el-checkbox__inner{
		background-color: #FF4936;
		border-color: #FF4936;
	}
	.loginDialog .el-dialog__headerbtn:focus .el-dialog__close, 
	.loginDialog .el-dialog__headerbtn:hover .el-dialog__close{
		color: #FF4936;
	}
	.Noticedialog .el-dialog {
		display: flex;
		flex-direction: column;
		margin: 0 !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	 
	.Noticedialog .el-dialog .el-dialog__body {
		flex: 1;
		overflow: auto;
	}
</style>
